<template>
  <div class="pa-2">
    <!-- キャスト取込ダイアログ -->
    <v-dialog v-model="dialog" scrollable persistent width="900px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="info font-weight-bold" v-bind="attrs" v-on="on"
          ><v-icon left>mdi-cloud-download-outline</v-icon
          ><span class="d-none d-sm-flex">キャスト取込</span
          ><span class="d-sm-none">取込</span></v-btn
        >
      </template>
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1">
            取込媒体選択
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 2" step="2">
            取込キャスト選択
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3">取込の確認</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items class="stepper-scrollable-content">
          <v-stepper-content step="1" class="pa-0">
            <step-1-component
              ref="step1"
              @click-cancel="onCancel"
              @click-back="onBack(0)"
              @click-next="onNext(2)"
            ></step-1-component>
          </v-stepper-content>
          <v-stepper-content step="2" class="pa-0">
            <step-2-component
              ref="step2"
              @click-cancel="onCancel"
              @click-back="onBack(1)"
              @click-next="onNext(3)"
            ></step-2-component>
          </v-stepper-content>
          <v-stepper-content step="3" class="pa-0">
            <step-3-component
              ref="step3"
              @click-cancel="onCancel"
              @click-back="onBack(2)"
              @sync-ok="syncOk"
            ></step-3-component>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, defineComponent } from "@vue/composition-api";
import store from "@/store/index.js";
import Step1Component from "./step1.vue";
import Step2Component from "./step2.vue";
import Step3Component from "./step3.vue";

export default defineComponent({
  components: {
    Step1Component,
    Step2Component,
    Step3Component,
  },
  setup(_, ctx) {
    const state = reactive({
      e1: 1,
      shopSites: {},
      shopSiteId: 0,
      defaultShopSiteId: 0,
      dialog: false,
      step1: null,
      step2: null,
      step3: null,
    });

    // 初期化処理
    const init = () => {
      state.e1 = 1;
      store.dispatch("synchro_cast/clear");
    };
    init();

    const step_init = (to) => {
      switch (to) {
        case 1:
          state.step1.init();
          break;
        case 2:
          state.step2.init();
          break;
        case 3:
          state.step3.init();
      }
    };

    const scrollReset = () => {
      setTimeout(() => {
        document.querySelector(".stepper-scrollable-content").scroll(0, 0);
      }, 200);
    };

    const onCancel = () => {
      state.dialog = false;
      init();
    };
    const onNext = (to) => {
      if (to === 0) return false;
      state.e1 = to;
      scrollReset();
      step_init(to);
    };
    const onBack = (to) => {
      if (to === 0) return false;
      scrollReset();
      state.e1 = to;
    };
    const onSubmit = () => {
      state.dialog = false;
    };
    const syncOk = async () => {
      state.dialog = false;
      init();
      ctx.emit("reset");
    };

    return {
      ...toRefs(state),
      onSubmit,
      onCancel,
      onNext,
      onBack,
      syncOk,
    };
  },
});
</script>
